// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
    background-image: url("/images/background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; 
    background-size: cover;

    color: #ddd;
}

.nav-border {
    border-bottom: solid #993009 $border-radius;
}

.btn.disabled {
    pointer-events: auto;
}

.link-external::after {
    content: url('/images/diagonal-arrow.svg');
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-left: 0.5em;
}

.card {
    background: rgba(130,130,130,.2);
    border: 2px solid;
    border-top-color: rgba(255,255,255,.4);
    border-left-color: rgba(255,255,255,.4);
    border-bottom-color: rgba(60,60,60,.4);
    border-right-color: rgba(60,60,60,.4);
}
.card label, .auth_form .card-header {
    color: rgba(180,180,180,.8);
}

.card-header {
    //border-bottom: solid MediumTurquoise 2px;
    border-bottom: solid #993009 4px;
}
.card-footer {
    border-top: solid #993009 4px;
}

.cron-dropdown {
    text-align: center;
    -moz-text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}

svg.graph {
    background-color: rgba(255, 255, 255, .7);
    //visibility: hidden;
    //display: none;
    color: black;
}
#svg-container {
    position: absolute;
}

table > tbody > tr {
    height: 4em;
}

.svg_symbols {
    margin-left: 0.4em;
}

.badge {
    font-size: .8rem;
}

// css for gpio visualisations
.svg_gpio_doors {
    transform-box: fill-box;
    transform-origin: center;
    fill: white;
    stroke-width: 3;
    stroke: grey;
}
.svg_gpio_device_rect_input {
    transform-box: fill-box;
    transform-origin: center;
    fill: white;
    stroke-width: 0.2em;
    stroke: red;
}
.svg_gpio_device_rect_output {
    transform-box: fill-box;
    transform-origin: center;
    fill: white;
    stroke-width: 0.2em;
    stroke: red;
    cursor: pointer;
}
.svg_gpio_device_circle_output {
    transform-box: fill-box;
    transform-origin: center;
    fill: white;
    stroke-width: 0.2em;
    stroke: red;
    cursor: pointer;
}


// rotating gears on loading logs/statistics page
.gears{
    padding-top: 10em;
    width:100%;
    display: block;
    position: absolute;
}
.gears-background {
    height: 600px;  // height from svg height in renderGraph
    background-color: rgba(50,50,50,.8);
}
.gears-container{
    width: 7rem; 
    height: 7rem;
    position: relative; 
    margin: 0rem auto;
}
.gear-rotate{
    width: 2rem;
    height: 2rem;
    top: 50%; 
    left: 50%; 
    margin-top: -1rem;
    margin-left: -.1rem;
    background: #E9E581;
    position: absolute;
    border-radius: 1rem;
    -webkit-animation: 1s gear-rotate linear infinite;
    -moz-animation: 1s gear-rotate linear infinite;
    animation: 1s gear-rotate linear infinite;
}
.gear-rotate-left{
    margin-top: -2.2rem;
    margin-left: 1.1rem;
    top: 50%;
    width: 2rem;
    height: 2rem;
    background: #E9E581;
    position: absolute;
    border-radius: 1rem;
    -webkit-animation: 1s gear-rotate-left linear infinite;
    -moz-animation: 1s gear-rotate-left linear infinite;
    animation: 1s gear-rotate-left linear infinite;
}
.gear-rotate::before, .gear-rotate-left::before {
    width: 2.8rem;
    height: 2.8rem;
    background: 
        -webkit-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
        -webkit-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
        -webkit-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
    background: 
        -moz-linear-gradient(0deg,transparent 39%,#E9E581 39%,#47EC19 61%, transparent 61%),
        -moz-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
        -moz-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
    background: 
        -o-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
        -o-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
        -o-linear-gradient(120deg,transparent 42%,#47EC19 42%,#E9E581 58%, transparent 58%);
    background: -ms-linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),-ms-linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),-ms-linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
    background: 
        linear-gradient(0deg,transparent 39%,#E9E581 39%,#E9E581 61%, transparent 61%),
        linear-gradient(60deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%),
        linear-gradient(120deg,transparent 42%,#E9E581 42%,#E9E581 58%, transparent 58%);
    position: absolute;
    content:"";
    top: -.4rem;
    left: -.4rem;
    border-radius:1.4rem;
}
.gear-rotate::after, .gear-rotate-left::after {
    width: 1rem;
    height: 1rem;
    background: #2B2B2B;
    position: absolute;
    content:"";
    top: .5rem;
    left: .5rem;
    border-radius: .5rem;
}
  
/*
* Keyframe Animations 
*/
@-webkit-keyframes gear-rotate {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(-180deg); }
}
@-moz-keyframes gear-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-180deg); }
}
@keyframes gear-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-180deg); }
}
@-webkit-keyframes gear-rotate-left {
   0% { -webkit-transform: rotate(30deg); }
    100% { -webkit-transform: rotate(210deg); }
}
@-moz-keyframes gear-rotate-left {
   0% { -webkit-transform: rotate(30deg); }
    100% { -webkit-transform: rotate(210deg); }
}
@keyframes gear-rotate-left { 
    0% { -webkit-transform: rotate(30deg); }
    100% { -webkit-transform: rotate(210deg); }
}

// css for ploar clock
div#clock {
    margin: auto;
    margin-top: 2em;
    width: 50%;
    height: 50%;
    display: block;
}